<!-- 信息动态-->
<template>
  <div class="harmful">
    <el-row>
      <el-col :span="13">
        <el-carousel>
          <el-carousel-item v-for="item in arrimg" :key="item">
            <el-image :src="item"></el-image>
          </el-carousel-item>
        </el-carousel>
      </el-col>
      <el-col :span="11">
        <el-card shadow="never">
          <div slot="header" class="ding">
            <div>公告通知</div>
            <div class="fontone" @click="jinlist(0)">更多</div>
          </div>

          <div
            v-for="(item, index) in list"
            :key="index"
            class="boxitems"
            @click="godetail(item.sharesalt)"
          >
            <span>{{ item.createtime.slice(0, 10) }}</span>
            {{ item.title }}
          </div>
        </el-card>
      </el-col>
    </el-row>
    <div class="tabox">
      <ul>
        <li :class="num == 1 ? 'active' : ''" @click="qieh(1)">
          <img src="../assets/img/infos/img01.png" />网信动态
        </li>
        <li :class="num == 2 ? 'active' : ''" @click="qieh(2)">
          <img src="../assets/img/infos/img02.png" />网安知识
        </li>
        <li :class="num == 3 ? 'active' : ''" @click="qieh(3)">
          <img src="../assets/img/infos/img03.png" />便民政策
        </li>
      </ul>
      <div class="fonttwo" @click="jinru">更多</div>
      <div class="tabconts">
        <div v-show="num == 1" class="tbitem">
          <dl
            @click="godetail(item.sharesalt)"
            v-for="(item, index) in onelist"
            :key="index"
          >
            <dt>
              <span>●</span>
              {{ item.title }}
            </dt>
            <dd>{{ item.createtime.slice(0, 10) }}</dd>
          </dl>
        </div>
        <div v-show="num == 2" class="tbitem">
          <dl
            @click="godetail(item.sharesalt)"
            v-for="(item, index) in onelist"
            :key="index"
          >
            <dt>
              <span>●</span>
              {{ item.title }}
            </dt>
            <dd>{{ item.createtime.slice(0, 10) }}</dd>
          </dl>
        </div>
        <div v-show="num == 3" class="tbitem">
          <dl
            @click="godetail(item.sharesalt)"
            v-for="(item, index) in onelist"
            :key="index"
          >
            <dt>
              <span>●</span>
              {{ item.title }}
            </dt>
            <dd>{{ item.createtime.slice(0, 10) }}</dd>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api/modules/infos.js";
export default {
  data() {
    return {
      arrimg: [],
      num: 1,
      list: [],
      onelist: [], //分类数组
    };
  },
  mounted() {
    this.sharelist();
    this.fenlist(0);
    this.bannan();
  },
  methods: {
    // 进入列表
    jinru() {
      let key = "name";
      let value = "";
      if(this.num == 1){
        value = '网信动态'
      }else if(this.num == 2){
        value = '网安知识'
      }else if(this.num == 3){
        value = '便民政策'
      }
      this.$store.state.loginMessage[key] = value;
      window.localStorage.setItem("key", value);
      this.jump("infoslist", this.$store.state.loginMessage);
    },
    // 进入列表
    jinlist(num) {
      let key = "name";
      let value = "公告通知";
      this.$store.state.loginMessage[key] = value;
      window.localStorage.setItem("key", value);
      this.jump("infoslist", this.$store.state.loginMessage);
    },
    //图
    async bannan() {
      let res = await API.banner().then((res) => {
        if (res.code == 1) {
          // console.log(res,'sjksa');
          this.arrimg = res.data.images;
        }
      });
    },
    qieh(num) {
      this.num = num;
      this.fenlist(num);
    },
    //跳转到详情页
    godetail(id) {
      // window.localStorage.setItem("tid", id);
      this.$store.state.loginMessage.wid = id;
      window.localStorage.setItem("xin", 1);
      const routeData = this.$router.resolve({
        path: "/infosdetail",
        query: this.$store.state.loginMessage,
      });
      window.open(routeData.href, "_blank");
    },
    // 列表详情
    async sharelist() {
      let res = await API.share({
        search: "",
        page: 1,
        pageLine: 10,
        type: 0,
      }).then((res) => {
        if (res.code == 1) {
          this.list = res.data.list.splice(0, 5);
        }
      });
    },
    // 列表详情
    async fenlist(num) {
      let res = await API.share({
        search: "",
        page: 1,
        pageLine: 10,
        type: num,
      }).then((res) => {
        if (res.code == 1) {
          this.onelist = res.data.list.splice(0, 6);
        }
      });
    },
  },
};
</script>

<style scoped>
.harmful {
  width: 1200px;
  padding: 20px 0 0 0;
}

/deep/.el-carousel__indicator .el-carousel__button {
  height: 10px;
  width: 10px;
  border-radius: 50px;
}

/deep/.el-carousel__container {
  height: 420px;
}

/deep/.el-carousel__indicators--horizontal {
  left: 60%;
  bottom: 12px;
}

/deep/.el-carousel__indicator--horizontal {
  padding: 12px 8px;
}

/deep/.el-card__header {
  border: medium none;
  font-size: 16px;
  font-weight: bold;
}

.boxitems {
  padding: 23.4px 0;
  font-size: 16px;
  color: rgba(85, 85, 85, 1);
  border-bottom: 1px dotted rgba(235, 235, 235, 1);
}
.boxitems:hover {
  color: #000;
  cursor: pointer;
}

.boxitems span {
  float: right;
  color: rgba(176, 176, 176, 1);
  font-size: 14px;
}

/deep/.el-card__body {
  padding: 0 20px;
}

.active {
  background: #ffffff !important;
  border: 1px solid #1189eb !important;
  box-shadow: 0 9px 12px 0 #dfedf7 !important;
  position: relative;
}
.active::after {
  content: "";
  position: absolute;
  top: 110%;
  transform: translate(-759%, -120%) rotate(315deg);
  width: 15px;
  height: 15px;
  background: #ffffff;
  border: 1px solid #1189eb;
  border-style: none none solid solid;
}

.tabox {
  padding: 20px 0;
}

.tabox ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tabox ul li {
  font-size: 24px;
  color: #000000;
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #dbdbdb;
  padding: 22px 81px;
}

.tabox ul li img {
  width: 76px;
  height: 76px;
  vertical-align: middle;
  margin: 0 55px 0 0;
}

.tbitem dl {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  border-bottom: 1px dotted rgba(235, 235, 235, 1);
}
.tbitem dl dt {
  font-size: 16px;
  color: #555555;
}
.tbitem dl dt:hover {
  color: #000;
  cursor: pointer;
}
.tbitem dl dd {
  font-size: 14px;
  color: #b0b0b0;
}

.tbitem dl dt span {
  color: #ff4800;
  padding: 0 8px 0 0;
}

.tabconts {
  padding: 10px 20px 20px 20px;
  background: #fff;
}
::v-deep(.el-image) {
  width: 100%;
  height: 100%;
}
.ding {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.fontone {
  font-size: 13px;
  font-weight: 400;
  color: #3eacdd;
  cursor: pointer;
  text-decoration: underline;
}
.fonttwo {
  width: 100%;
  text-align: right;
  margin-top: 20px;
  font-size: 13px;
  font-weight: 400;
  color: #3eacdd;
  cursor: pointer;
  text-decoration: underline;
}
</style>